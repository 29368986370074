<template>
    <div class="gifwall">
        <div v-for="i in rows" v-bind:key="'row-'+i">
            <div class="gifcontainer" v-for="n in cols" v-bind:key="'row-'+i+'-col-'+n" :style="'width:'+gifW+'px; height:'+gifH+'px; background-color: #'+Math.ceil(Math.random()*989998)+100000+';float:left;background-image: url(https://pub-5c5bf1df7ec34e91a87d40a6764b3c87.r2.dev/'+ randomGif() +'.gif);'">
            </div>
        </div>
    </div>
</template>
  
  <script>
  export default {
    name: 'GifWall',
    props: ['gifW', 'gifH', 'cols', 'rows'],
    data() {
        return {
            gifs: [
                1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,
                21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,
                41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,
                61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,
                81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,
                101,102,103,104,105
            ],
            used: []
        }
    },
    computed: {
        gifItemStyles() {
            return "font-color:red; width:" + this.gifW + "px, height:" + this.gifH + "px, background-color: #" + Math.ceil(Math.random(899998)) + ";";
        }
    },
    methods: {
        randomGif() {
            let gifNo = this.gifs[Math.floor(Math.random()*this.gifs.length)];
            if(!this.used.includes(gifNo)) {
                this.used.push(gifNo);
                return gifNo;
            }
            else {
                return this.randomGif();
            }
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
    .gifwall {
        float:left;
    }

.gifcontainer {
    font-weight: bold;
    background-position: center;
    background-size: cover;

}
  </style>
  
<template>
  <div id="app" style="margin:0; padding:0; width:101%; height:100%;">
    <!--
    {{ w }} {{ h }} {{ pxPerCol }} {{ pxPerRow }} {{ cols }} 
    <img alt="Vue logo" src="./assets/logo.png">
    -->
    <GifWall :key="w+h" :gifH="pxPerRow" :gifW="pxPerCol" :cols="cols" :rows="3"/>
  </div>
</template>

<script>
import GifWall from './components/GifWall.vue'

export default {
  name: 'App',
  data() {
    return {
      w: 0,
      h: 0,
      pxPerRow: 0,
      pxPerCol: 0,
      cols: 0,
    }
  },
  methods: {
    updateSize() {
      this.w = document.documentElement.clientWidth;
      this.h = document.documentElement.clientHeight;
      this.pxPerRow = this.h / 3;
      this.cols = Math.ceil(this.w / this.pxPerRow);
      this.pxPerCol = this.w / this.cols;
    }
  },
  components: {
    GifWall
},
  mounted() {
    this.updateSize();
    addEventListener('resize', () => {
      this.updateSize();
    });
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
